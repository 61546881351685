function patientgallery() {
    return (
        <section className="static-media smartop">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="section-title text-center aos-init aos-animate"
                            data-aos="fade-up"
                        >
                            <p>at VAGUS COSMETICS Islamabad</p>
                            <h2 className="title">
                                <span>Patient </span> Images & Videos
                            </h2>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="img/slider/before-after1.webp"
                                            target="_blank"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after1.webp"
                                                alt="gallery"
                                            />
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="img/slider/before-after1.webp"
                                            target="_blank"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after2.webp"
                                                alt="gallery"
                                            />
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="img/slider/before-after1.webp"
                                            target="_blank"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after3.webp"
                                                alt="gallery"
                                            />
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="https://www.youtube.com/watch?v=-2ppXiSiUnw"
                                            target="_parent"
                                            rel="noopener"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after-video-1.webp"
                                                alt="gallery"
                                            />
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="https://www.youtube.com/watch?v=fOTa4ynK2aw"
                                            target="_parent"
                                            rel="noopener"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after-video-2.webp"
                                                alt="gallery"
                                            />
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-4 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="gallery-item mb-30">
                            <div className="react_lightgallery_item">
                                <div className="thumb">
                                    <div className="lightbox-image">
                                        <a
                                            href="https://www.youtube.com/watch?v=LP-c5ACSwyU"
                                            target="_parent"
                                            rel="noopener"
                                        >
                                            <img
                                                width={366}
                                                height={245}
                                                src="img/slider/before-after-video-3.webp"
                                                alt="gallery"
                                            />{" "}
                                        </a>
                                    </div>
                                    <div className="overlay">
                                        <i className="icofont-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default patientgallery;
