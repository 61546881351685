import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Contact, <span>Information</span>
                </h2>
                <p>House# 27, main Kaghan Rd, sector F-8/3, Islamabad</p>
                <Link to="/">
                    <span>Email:</span> ranairfandr@gmail.com
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Call directly:</h6>
                    <p>(+9251) 2287661 - (+92) 3325017478</p>
                </div>
                <div className="info-tem">
                    <h6>Head Offices:</h6>
                    <p>House# 27, main Kaghan Rd, sector F-8/3, Islamabad</p>
                </div>
                <div className="info-tem mb-0">
                    <h6>Work Hours:</h6>
                    <p>Mon - Sat: 8.00 - 17.00, Sunday closed</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
