function featureboxthree() {
    return (
        <section className="static-media smartop">
            <div className="container">
                <div className="row mb-n4">
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="media featuresthreebg1">
                            <div className="media-body">
                                <h4 className="featuresthreetitle">
                                    Hair Transplant
                                </h4>
                                <p className="featuresthreep">
                                    Vagus Cosmetics offer cheap and best Hair
                                    replacement solutions which are incomparable
                                    in the market performed by Dr.Rana Irfan
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="media featuresthreebg2">
                            <div className="media-body">
                                <h4 className="featuresthreetitle">
                                    Skin Care Treamtent
                                </h4>
                                <p className="featuresthreep">
                                    Vagus Cosmetics treatment that revives the
                                    beauty damages that affects the skin
                                    involved deep lines, frowns, sagging skin,
                                    scar
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="media featuresthreebg3">
                            <div className="media-body">
                                <h4 className="featuresthreetitle">
                                    Skin Laser Treatment
                                </h4>
                                <p className="featuresthreep">
                                    The laser treatment is done to remove the
                                    minor flaws in the skin.Including removal of
                                    excess hairs, stretch marks, birthmarks and
                                    many more
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default featureboxthree;
