import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import MedicalFeature from "../../../components/medical-feature";
import HomeAbout from "../../../components/homeabout";

const MedicalFeatureContainer = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            subTitle="VAGUS is a one stop solution"
                            title="Welcome To <span>Vagus Cosmetics</span> "
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-xl-8">
                        <div
                            className="row icon-box-style"
                            data-aos="fade-up"
                            data-aos-duration="1100"
                        >
                            <HomeAbout />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="thumb"
                data-aos="fade-left"
                data-aos-duration="1500"
            >
                <img
                    width={654}
                    height={609}
                    src={
                        process.env.PUBLIC_URL + "./img/photos/homeprofile.webp"
                    }
                    alt="hope"
                />
            </div>
        </section>
    );
};

export default MedicalFeatureContainer;
