import { Link } from "react-router-dom";
import DepartmentWidget from "../../components/sidebar/department";
import PropTypes from "prop-types";
import SidebarWorkingTime from "../../components/sidebar/sidebar-working-time";
import Appointment from "../../components/sidebar/appointment";
import ServiceDetails from "../../components/service-details";
import AccordionWrapTwo from "../../components/accordion/AccordionWrapTwo.jsx";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../data/home.json";
import ServiceData from "../../data/service.json";
import CasesItem from "../../components/cases";

SwiperCore.use([Pagination]);
const ServiceDetailsContainer = ({ data }) => {
    const swiperOption = {
        slidesPerView: 2,
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: { clickable: true },
        breakpoints: {
            1200: {
                slidesPerView: 2,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            576: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    return (
        <section className="department-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="department-wrpp">
                            <div className="sidebar-wrapper">
                                <DepartmentWidget data={ServiceData} />
                                <SidebarWorkingTime />
                                <Appointment />
                            </div>

                            <div className="department-content">
                                <ServiceDetails data={data} />

                                <div className="team-single">
                                    <h2 className="title">
                                        About <span>Surgeon</span>
                                    </h2>
                                    <div className="membr-info">
                                        <h4 className="name">Dr. Rana Irfan</h4>
                                        <h5 className="designation">
                                            Founder, CEO, MD
                                        </h5>
                                        <div className="desc">
                                            <p>
                                                With immense qualifications and
                                                experiences in the field of Hair
                                                Transplant & Restoration, Dr.
                                                Rana Irfan is one of the most
                                                credible Hair Restoration
                                                Surgeon from Pakistan. With his
                                                MD from Quaid e Azam Medical
                                                College Bahawalpur in 1993 Dr.
                                                Rana has over 2 decades of
                                                experience in the field of Hair
                                                Restoration. He currently holds
                                                a membership at:
                                                <ul>
                                                    <li>
                                                        <span></span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            The European Society
                                                            of Hair Restoration
                                                            Surgery
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            International
                                                            Society of Hair
                                                            Restoration Surgery
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            American Society of
                                                            Hair Restoration
                                                            Surgery
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Asian Society of
                                                            Hair Restoration
                                                            Surgery
                                                        </span>
                                                    </li>
                                                </ul>
                                            </p>
                                            <ul>
                                                <li>
                                                    <span>
                                                        Years in Practice
                                                    </span>
                                                    <span>25 Years</span>
                                                </li>
                                                <li>
                                                    <span>Email</span>
                                                    <span>
                                                        info@drranairfan.com
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Skype</span>
                                                    <span>vaguscosmetics1</span>
                                                </li>
                                            </ul>
                                            <Link to="/" className="btn-link">
                                                VIEW FULL PROFILE
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="thumb">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/img/photos/doctor-02.webp"
                                            }
                                            alt="hope"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ServiceDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ServiceDetailsContainer;
