import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accordion";

const AccordionWrap = () => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            <AccordionItem id="one">
                <AccordionTitle id="one">What causes hair loss?</AccordionTitle>
                <AccordionContent id="one">
                    The most common cause of hair loss is inheritance. Men and
                    women inherit the gene for hair loss from either or both
                    parents. Men are most commonly affected by the inherited
                    gene as the hormone, testosterone, activates the genetic
                    program causing loss of hair follicles
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitle id="two">
                    Do hair transplants really work?
                </AccordionTitle>
                <AccordionContent id="two">
                    Yes. The transplanted hair is removed from one area of the
                    body (donor site) and transferred to another (recipient
                    site). The transferred tissue is not “rejected” as it is not
                    foreign tissue. The transplanted hair maintains its own
                    characteristics; colour, texture, growth rate, and curl,
                    after transplantation and regrowth.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitle id="three">
                    Are hair transplants painful?
                </AccordionTitle>
                <AccordionContent id="three">
                    Most people are surprised at how little pain there is during
                    the procedure. Some discomfort is to be expected as the
                    anaesthetic is injected into the scalp. Once the skin is
                    anaesthetized, there is no pain. If the numbing medicine
                    wears off during the course of the procedure, more is
                    injected to re-anaesthetize the area
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="four">
                <AccordionTitle id="four">
                    When can I go back to work?
                </AccordionTitle>
                <AccordionContent id="four">
                    Depending on the type of procedure you have done and the
                    type of work you do, it is often possible to go back to work
                    the next day. Your hair restoration surgeon will discuss
                    this with you during the consultation.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWrap;
