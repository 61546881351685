import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13275.436038141248!2d73.0471212!3d33.712597!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf0427f63f1d%3A0xe63047d5b468f69c!2sVagus%20Cosmetics%20Hair%20Transplant%20Islamabad!5e0!3m2!1sen!2s!4v1681211715772!5m2!1sen!2s"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
