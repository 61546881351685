function homeabout() {
    return (
        <section className="static-media">
            <p className="greenfont">
                VAGUS is a one stop solution for all your cosmetic and personal
                aesthetic needs. VAGUS Islamabad Pakistan, a place where quality
                meets innovation!
            </p>
            <p>
                The Company is currently working in the domains of Hair
                Transplant, Cosmetic Surgery, Shanze Cosmetics and Infertility
                Solutions, The Company is found in 2002 by Dr Rana Irfan. We
                also offer a huge collection of Medical resources to help out
                the Field Professionals and Medical Students. With an elite
                clientele in the Middle East, Europe and the US, VAGUS is the
                name of trust when it comes to aesthetics. With latest equipment
                and most experienced professionals on board, VAGUS Islamabad
                Pakistan is the only Aesthetic solution you need. Re-invent your
                look and find a new you with VAGUS. You would be surprised to
                see your potential.
            </p>

            <div className="row mb-n4">
                <div className="col-lg-6 col-sm-6 mb-6">
                    <div className="icon">
                        <img
                            width={60}
                            height={40}
                            alt="hair transplant"
                            src="./img/photos/hair-transplant-icon.webp"
                        />

                        <span className="greenfont-font">Hair Transplant</span>
                    </div>
                    <p>
                        Hair transplant is a simple surgical process in which
                        hair follicles (cells which grow hairs) are collected
                        from the sides of the ear and back of the head called
                        the donor area (the area from which hairs are taken) and
                        move them to the recipient area (the area which requires
                        hair). Basically, in this process hair grafts (thin
                        tissues) move to areas which suffer from balding or thin
                        hairs.
                    </p>
                </div>

                <div className="col-lg-6 col-sm-6 mb-6">
                    <div className="icon">
                        <img
                            width={60}
                            height={40}
                            alt="Cosmetic Surgery"
                            src="./img/photos/cosmetics-icon.webp"
                        />
                        <span className="greenfont-font">Cosmetic Surgery</span>
                    </div>
                    <p>
                        In PRP, we used your own blood healing substance PLASMA
                        and used it on your skin, to stimulate the production of
                        healthy tissue, recover the tissues and to get back the
                        soft and smooth texture. PRP is now serving as the
                        cutting edge considerably when it comes to regenerative
                        skin therapies.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default homeabout;
